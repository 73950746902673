.custom-toolbar {
    height: 68px !important;
}

.logo {
    height: 44px;
}

.divider {
    border-right: 1px solid #ffffff88;
    width: 1px;
    height: 30px;
    margin: 0 1rem;
}

.logout-button {
    margin-left: 1rem !important;
}