.with-header-footer-container {
    margin: 2rem;
    padding-bottom: 2.5rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    background: #000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    align-content: space-between;
    gap: 10px;
}